import * as MutationsType from "./mutationsType";
import { categoriesCollection } from "@/config/firebase";

export default {
  async getActiveCategories({ commit }) {
    commit(MutationsType.SET_CATEGORIES, null);
    await categoriesCollection
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        let categories = [];
        querySnapshot.forEach((doc) => {
          categories.push({ id: doc.id, ...doc.data() });
        });
        commit(MutationsType.SET_CATEGORIES, categories);
      })
      .catch((error) => {
        console.log("error getActiveCategories: ", error);
      });
  },
  async getCategoryById({ commit }, payload) {
    commit(MutationsType.SET_CATEGORY, null);
    await categoriesCollection.doc(payload.id).onSnapshot((doc) => {
      commit(MutationsType.SET_CATEGORY, { id: doc.id, ...doc.data() });
    });
  },
};
