import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_CATEGORY](state, payload) {
    state.category = payload;
  },
  [MutationsType.SET_CATEGORIES](state, payload) {
    state.categories = payload;
  },
};
