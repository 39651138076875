import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_CAMPAIGNS](state, payload) {
    state.campaigns = payload;
  },
  [MutationsType.SET_CAMPAIGN](state, payload) {
    state.campaign = payload;
  },
  [MutationsType.ADD_CAMPAIGN](state, payload) {
    state.campaigns.push(payload);
  },
  [MutationsType.RESET_CAMPAIGNS](state) {
    state.campaigns = [];
  },
};
