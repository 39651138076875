import * as MutationsType from "./mutationsType";
import { campaignsCollection, rankingsCollection, serverTimestamp } from "@/config/firebase";
import validateDuration from "@/utils/validate_duration";

export default {
  async getAllCampaigns({ commit }) {
    commit(MutationsType.SET_CAMPAIGNS, null);
    await campaignsCollection
      .get()
      .then((querySnapshot) => {
        let campaigns = [];
        querySnapshot.forEach(async (doc) => {
          await campaigns.push({ id: doc.id, ...doc.data() });
        });
        commit(MutationsType.SET_CAMPAIGNS, campaigns);
      })
      .catch((error) => {
        console.error("error getAllCampaigns: ", error);
      });
  },
  async getActiveCampaigns({ commit }, payload) {
    commit(MutationsType.SET_CAMPAIGNS, null);
    await campaignsCollection
      .where("active", "==", true)
      .orderBy("date_start", "desc")
      .get()
      .then((querySnapshot) => {
        let campaigns = [];
        querySnapshot.forEach((doc) => {
          let campaign = doc.data();
          if (!payload || (payload.on_landing && campaign.appear_on_landing))
            campaigns.push({ id: doc.id, ...campaign });
        });
        commit(MutationsType.SET_CAMPAIGNS, campaigns);
      })
      .catch((error) => {
        console.error("error getActiveCampaigns: ", error);
      });
  },
  async getCampaignById({ commit }, payload) {
    commit(MutationsType.SET_CAMPAIGN, null);
    await campaignsCollection.doc(payload.id).onSnapshot(async (doc) => {
      let campaign = doc.data();
      let is_valid = validateDuration(serverTimestamp, campaign.date_start.toDate(), campaign.date_end.toDate());
      commit(MutationsType.SET_CAMPAIGN, { id: doc.id, ...campaign, is_valid: is_valid });
    });
  },
  async getCampaignsByUser({ commit }, payload) {
    commit(MutationsType.RESET_CAMPAIGNS);
    let querySnapshot = await campaignsCollection.get();
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        let campaign = { id: doc.id, ...doc.data() };
        if (payload.user.role === "super_admin" || (campaign.roles && campaign.roles[payload.user.uid])) {
          commit(MutationsType.ADD_CAMPAIGN, campaign);
        }
      });
    }
  },
  async getCampaignsByBrand({ commit }, payload) {
    await campaignsCollection
      .where("active", "==", true)
      .where("brand.id", "==", payload.brand_id)
      .get()
      .then((querySnapshot) => {
        commit(MutationsType.RESET_CAMPAIGNS);
        querySnapshot.forEach(async (doc) => {
          commit(MutationsType.ADD_CAMPAIGN, { id: doc.id, ...doc.data() });
        });
      })
      .catch((error) => {
        console.error("error getCampaignsByBrand: ", error);
      });
  },
  async getCampaignsByCategory({ commit }, payload) {
    await campaignsCollection
      .where("active", "==", true)
      .where("category.id", "==", payload.category_id)
      .orderBy("date_start", "desc")
      .get()
      .then((querySnapshot) => {
        commit(MutationsType.RESET_CAMPAIGNS);
        querySnapshot.forEach(async (doc) => {
          commit(MutationsType.ADD_CAMPAIGN, { id: doc.id, ...doc.data() });
        });
      })
      .catch((error) => {
        console.error("error getCampaignsByCategory: ", error);
      });
  },
  // eslint-disable-next-line
  async getCampaignRanking({ commit }, payload) {
    let querySnapshot = await rankingsCollection
      .doc(payload.campaign_id)
      .collection("records")
      .orderBy("amount", "desc")
      .orderBy("created_at", "asc")
      .get();

    let top = payload.top || querySnapshot.size;
    if (querySnapshot.size < top) top = querySnapshot.size;

    let records = querySnapshot.docs;
    let ranking_records = [];
    let user_found = false;
    for (var i = 0; i < top; i++) {
      if (records[i].id === payload.user_id) user_found = true;
      ranking_records.push({ position: i, ...records[i].data() });
    }

    if (!user_found) {
      let user_record = records.find((r) => {
        return r.id === payload.user_id;
      });
      let position = records.indexOf(user_record);
      ranking_records.push({ position: position, ...user_record.data() });
    }
    return ranking_records;
  },
};
