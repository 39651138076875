import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_USER](state, payload) {
    state.user = payload;
  },
  [MutationsType.SET_USER_NOTIFY_ADS](state, payload) {
    state.user.notify_ads = payload;
  },
};
