export default {
  user: null,
  documentTypes: {
    CEPE: {
      name: "Carnet de Extranjería",
    },
    DNIPE: {
      name: "DNI",
    },
  },
  roles: [{ id: "admin", name: "Administrador" }, { id: "super_admin", name: "Super administrador" }],
};
