import Vue from "vue";
import Vuex from "vuex";
import User from "@/store/modules/user";
import Brand from "@/store/modules/brand";
import Campaign from "@/store/modules/campaign";
import Record from "@/store/modules/record";
import Category from "@/store/modules/category";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User,
    Brand,
    Campaign,
    Record,
    Category,
  },
});
