import * as MutationsType from "./mutationsType";
import { brandsCollection } from "@/config/firebase";

export default {
  async getAllBrands({ commit }) {
    commit(MutationsType.SET_BRANDS, null);
    await brandsCollection
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        let brands = [];
        querySnapshot.forEach(async (doc) => {
          await brands.push({ id: doc.id, ...doc.data() });
        });
        commit(MutationsType.SET_BRANDS, brands);
      })
      .catch((error) => {
        console.log("error getAllBrands: ", error);
      });
  },
  async getBrandById({ commit }, payload) {
    let brand = await brandsCollection.doc(payload.id).get();
    commit(MutationsType.SET_BRAND, brand.exists ? { id: brand.id, ...brand.data() } : null);
  },
};
