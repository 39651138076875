import * as MutationsType from "./mutationsType";
import { auth, usersCollection, serverTimestamp, routeApi } from "@/config/firebase";

import axios from "axios";

import sendVerificationMail from "@/utils/send_email";
import getCookie from "@/utils/get_cookie";
import activeTracking from "@/config/analytics";

export default {
  async getInfo({ commit }, payload) {
    commit(MutationsType.SET_USER, null);
    if (payload.user) {
      let user = await usersCollection.doc(payload.user.uid).get();
      let token = await payload.user.getIdTokenResult();
      let user_role = token.claims && token.claims.role ? token.claims.role : "user";
      commit(MutationsType.SET_USER, {
        uid: payload.user.uid,
        emailVerified: auth.currentUser.emailVerified,
        role: user_role,
        ...user.data(),
      });
    }
  },
  async login({ commit }, payload) {
    try {
      let user = await auth.signInWithEmailAndPassword(payload.user.email, payload.user.password);
      commit(MutationsType.SET_USER, user);
      activeTracking();
      return { data: user };
    } catch (error) {
      return { error: error };
    }
  },
  async register({ commit }, payload) {
    try {
      let verifyDocument = await usersCollection.where("number_document", "==", payload.user.number_document).get();
      if (verifyDocument.empty) {
        let data = await auth.createUserWithEmailAndPassword(payload.user.email, payload.user.password);
        delete payload.user.password;
        commit(MutationsType.SET_USER, data.user);
        let user = {
          uid: data.user.uid,
          terms: { accept: payload.terms, accept_at: serverTimestamp },
          created_at: serverTimestamp,
          notify_ads: true,
          ...payload.user,
        };
        await usersCollection.doc(user.uid).set(user);
        activeTracking();
        sendVerificationMail();
        return { data: data };
      } else {
        throw { code: "DOCUMENT" };
      }
    } catch (error) {
      return { error: error };
    }
  },
  async handleAdNotifications({ commit }) {
    let response = await axios({
      url: `${routeApi}/user/handle-ad-notifications`,
      method: "post",
      headers: { Authorization: `Bearer ${getCookie("__session")}` },
      withCredentials: true,
    });
    if (response.data) commit(MutationsType.SET_USER_NOTIFY_ADS, response.data.notify_ads);
  },
  // eslint-disable-next-line
  async getUserById({ commit }, payload) {
    let doc = await usersCollection.doc(payload.user.id).get();
    return !doc.empty ? { id: doc.id, ...doc.data() } : null;
  },
  // eslint-disable-next-line
  async getUserByNumberDocument({ commit }, payload) {
    let response = await usersCollection.where("number_document", "==", payload.user.number_document).get();
    if (!response.empty) {
      return { uid: response.docs[0].id, ...response.docs[0].data() };
    }
    return;
  },
  // eslint-disable-next-line
  async resetPassword({ commit }, payload) {
    try {
      await auth.sendPasswordResetEmail(payload.user.email);
      return true;
    } catch (error) {
      return error;
    }
  },
  // eslint-disable-next-line
  async setPermissions({ commit }, payload) {
    try {
      let response = await axios({
        url: `${routeApi}/admin/set-permissions`,
        method: "post",
        headers: { Authorization: `Bearer ${getCookie("__session")}` },
        withCredentials: true,
        data: {
          user_email: payload.user.email,
          user_role: payload.user.role,
          campaign_id: payload.campaign.id,
        },
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  async logout({ commit }) {
    try {
      await auth.signOut();
      commit(MutationsType.SET_USER, null);
    } catch (error) {
      return;
    }
  },
};
