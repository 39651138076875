export default {
  getBrand(state) {
    return state.brand;
  },
  getBrands(state) {
    return state.brands;
  },
  getBrandsConverted(state) {
    let brands = {};
    if (state.brands) {
      state.brands.forEach((brand) => {
        brands[brand.slug] = brand;
      });
    }
    return brands;
  },
};
