export default {
  getUser(state) {
    return state.user;
  },
  getDocumentTypes(state) {
    return state.documentTypes;
  },
  getSendEmailVerify(state) {
    return state.sendEmailVerify;
  },
  getRoles(state) {
    return state.roles;
  },
};
