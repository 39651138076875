export default {
  getCampaigns(state) {
    return state.campaigns;
  },
  getCampaignsConverted(state) {
    let campaigns = {};
    if (state.campaigns) {
      state.campaigns.forEach((campaign) => {
        campaigns[campaign.id] = campaign;
      });
    }
    return campaigns;
  },
  getCampaign(state) {
    return state.campaign;
  },
};
