import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "@/views/Landing";
import Signup from "@/views/auth/Signup";
import Signin from "@/views/auth/Signin";
import Recovery from "@/views/auth/Recovery";
import Profile from "@/views/auth/Profile";
import Category from "@/views/partials/Category";
import Campaign from "@/views/partials/Campaign";
import Brand from "@/views/partials/Brand";
import Dashboard from "@/views/admin/Dashboard";
import ViewRecords from "@/views/admin/options/ViewRecords";
import CreateRecord from "@/views/admin/options/CreateRecord";
import Permissions from "@/views/admin/users/Permissions";
import Accruals from "@/views/admin/records/Accruals";
import Terms from "@/views/politics/Terms";
import Privacity from "@/views/politics/Privacity";

import { auth, campaignsCollection, brandsCollection, categoriesCollection } from "@/config/firebase";

Vue.use(VueRouter);

async function getUserRole() {
  let user = auth.currentUser;
  let role = "anonymous";
  if (user) {
    let token = await user.getIdTokenResult();
    role = token.claims && token.claims.role ? token.claims.role : "user";
  }
  return role;
}

async function verifyPermissions(role, roles) {
  return roles.find((r) => {
    return r === role;
  });
}

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/jw",
      redirect: "/latam-pass/jw/Qr1JUTl5kgpGIXDl7WH8",
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        authorizedRoles: ["admin", "super_admin"],
      },
    },
    {
      path: "/dashboard/permissions",
      name: "permissions",
      component: Permissions,
      meta: {
        authorizedRoles: ["super_admin"],
      },
    },
    {
      path: "/dashboard/accruals",
      name: "accruals",
      component: Accruals,
      meta: {
        authorizedRoles: ["super_admin"],
      },
    },
    {
      path: "/dashboard/:campaign_id/create",
      name: "create-record",
      component: CreateRecord,
      meta: {
        authorizedRoles: ["super_admin"],
      },
    },
    {
      path: "/dashboard/:campaign_id",
      name: "view-records",
      component: ViewRecords,
      meta: {
        authorizedRoles: ["admin", "super_admin"],
      },
      // eslint-disable-next-line
      async beforeEnter(to, from, next) {
        if (to.meta.role === "super_admin") {
          next();
        } else {
          let response = await campaignsCollection.doc(to.params.campaign_id).get();
          if (response.exists) {
            let roles = response.data().roles;
            roles && roles[auth.currentUser.uid] ? next() : next("/dashboard");
          } else {
            next("/dashboard");
          }
        }
      },
    },
    {
      path: "/latam-pass/c/:category_id",
      name: "category",
      component: Category,
      // eslint-disable-next-line
      async beforeEnter(to, from, next) {
        // Provisional
        let response = await categoriesCollection.doc(to.params.category_id).get();
        response.data() && response.data().active ? next() : next("/");
      },
    },
    {
      path: "/latam-pass/:brand_id",
      name: "brand",
      component: Brand,
      // eslint-disable-next-line
      async beforeEnter(to, from, next) {
        // Provisional
        let response = await brandsCollection.doc(to.params.brand_id).get();
        response.data() && response.data().active ? next() : next("/");
      },
    },
    {
      path: "/:brand_id/:campaign_id",
      redirect: "/latam-pass/:brand_id/:campaign_id",
    },
    {
      path: "/latam-pass/:brand_id/:campaign_id",
      name: "campaign",
      component: Campaign,
      // eslint-disable-next-line
      async beforeEnter(to, from, next) {
        // Provisional
        let response = await campaignsCollection.doc(to.params.campaign_id).get();
        response.data() && response.data().active && response.data().brand.id == to.params.brand_id
          ? next()
          : next("/");
      },
    },
    {
      path: "/",
      redirect: "/latam-pass",
    },
    {
      path: "/latam-pass",
      name: "landing",
      component: Landing,
    },
    {
      path: "/register",
      name: "signup",
      component: Signup,
      meta: {
        authorizedRoles: ["anonymous"],
      },
      async beforeEnter(to, from, next) {
        to.params.redirect_url = to.query.redirect_to || from.path;
        to.params.show_campaigns = !to.query.redirect_to && from.name !== "campaign";
        next();
      },
    },
    {
      path: "/login",
      name: "signin",
      component: Signin,
      meta: {
        authorizedRoles: ["anonymous"],
      },
      async beforeEnter(to, from, next) {
        to.params.redirect_url = from.name ? from.path : "/";
        next();
      },
    },
    {
      path: "/recovery-password",
      name: "recovery",
      component: Recovery,
      meta: {
        authorizedRoles: ["anonymous"],
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        authorizedRoles: ["user", "admin", "super_admin"],
      },
    },
    {
      path: "/terms",
      name: "terms",
      component: Terms,
    },
    {
      path: "/privacy-policy",
      name: "privacity",
      component: Privacity,
    },
    {
      path: "*",
      redirect: "/latam-pass",
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// eslint-disable-next-line
router.beforeEach(async (to, from, next) => {
  let authorizedRoles = to.meta.authorizedRoles;
  if (authorizedRoles) {
    let role = await getUserRole();
    let authorized = await verifyPermissions(role, authorizedRoles);
    if (!authorized) next("/");
    to.meta.role = role;
  }
  next();
});

export default router;
