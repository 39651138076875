import moment from "moment";

function validateDuration(date, date_start, date_end) {
  date = new Date(moment(date).format("YYYY-MM-DD")).getTime();
  date_start = new Date(moment(date_start).format("YYYY-MM-DD")).getTime();
  date_end = new Date(moment(date_end).format("YYYY-MM-DD")).getTime();
  return date_start <= date && date <= date_end;
}

export default validateDuration;
