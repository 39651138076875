import * as MutationsType from "./mutationsType";
import uid from "uid";
import calculateMiles from "@/utils/calculate_miles";
import { storage, serverTimestamp, recordsCollection } from "@/config/firebase";

export default {
  async getRecordsByCampaign({ commit }, payload) {
    commit(MutationsType.SET_RECORDS, null);
    await recordsCollection
      .where("campaign_id", "==", payload.campaign)
      .orderBy("created_at", "desc")
      .get()
      .then((querySnapshot) => {
        let records = [];
        querySnapshot.forEach(async (doc) => {
          await records.push(doc.data());
        });
        commit(MutationsType.SET_RECORDS, records);
      })
      .catch((error) => {
        console.error("error getRecordsByCampaign: ", error);
      });
  },
  async getRecordsByUser({ commit }, payload) {
    commit(MutationsType.SET_RECORDS, null);
    await recordsCollection
      .where("user_id", "==", payload.user)
      .orderBy("created_at", "desc")
      .get()
      .then((querySnapshot) => {
        let records = [];
        querySnapshot.forEach(async (doc) => {
          await records.push(doc.data());
        });
        commit(MutationsType.SET_RECORDS, records);
      })
      .catch((error) => {
        console.error("error getRecordsByCampaign: ", error);
      });
  },
  // eslint-disable-next-line
  async verifyRepeatVoucherRecord({ commit }, payload) {
    let recordExist = await recordsCollection
      .where("campaign_id", "==", payload.campaign_id)
      .where("voucher", "==", payload.record_voucher)
      .get();
    return recordExist.docs && recordExist.docs.length;
  },
  // eslint-disable-next-line
  async generateTokenRecord({ commit }) {
    return uid(8);
  },
  // eslint-disable-next-line
  async getRecord({ commit }, payload) {
    let record = await recordsCollection
      .where("campaign_id", "==", payload.campaign_id)
      .where("user_id", "==", payload.user_id)
      .get();
    return record.empty ? null : record.docs[0].data();
  },
  // eslint-disable-next-line
  async saveRecord({ commit }, payload) {
    try {
      let record = payload.record;
      let campaign = payload.campaign;
      let user = payload.user;
      let termsUrl = "";

      if (payload.creator_uid) {
        let fileName = `${new Date().getTime()}-${payload.creator_uid}.${payload.terms.image.name.split(".").pop()}`;
        let storageRef = await storage.ref().child(`records/${campaign.brand.id}/${campaign.id}/terms/${fileName}`);
        await storageRef.put(payload.terms.image);
        termsUrl = await storageRef.getDownloadURL();
      }

      let miles = 0;
      if (campaign.ratio) {
        let fieldToConvertRatio = campaign.ratio?.field_to_convert || "amount";
        miles = calculateMiles(record[fieldToConvertRatio], campaign.ratio);
      }

      let customFields = {};
      if (campaign.custom_fields && campaign.custom_fields.length)
        for (let cf of campaign.custom_fields) {
          customFields[cf] = record[cf];
        }

      let data = {
        user_id: user.uid,
        user: {
          email: user.email,
          full_name: `${user.name} ${user.lastname}`,
          type_document: user.type_document,
          number_document: user.number_document,
        },
        brand_id: campaign.brand.id,
        brand: {
          name: campaign.brand.name,
        },
        campaign_id: campaign.id,
        campaign: {
          name: campaign.name,
          type: campaign.type,
          show_ranking: campaign.show_ranking || false,
        },
        miles: miles,
        amount: Number(record.amount) || 0,
        voucher: record.voucher || "",
        token: record.token || "",
        store: record.store || "",
        file_url: payload.fileUrl || "",
        terms: {
          file_url: termsUrl,
          accept: payload.creator_uid ? true : record.terms,
          accept_at: serverTimestamp,
        },
        created_by: { uid: payload.creator_uid || user.uid },
        verify_status: "pendiente",
        accrual_status: "pendiente",
        created_at: serverTimestamp,
        ...customFields,
      };

      await recordsCollection.add(data);
      return { data: data };
    } catch (error) {
      return { error: error };
    }
  },
};
