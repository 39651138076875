import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_BRAND](state, payload) {
    state.brand = payload;
  },
  [MutationsType.SET_BRANDS](state, payload) {
    state.brands = payload;
  },
};
